import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // LocalStorage for web
import rootReducer from './reducers'; // Your combined reducers

// Persistence configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store using `configureStore`
const store = configureStore({
  reducer: persistedReducer, // Use the persisted reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Ignore serializability checks for persisted state
    }),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in dev mode
});

// Create the persistor for the store
const persistor = persistStore(store);

export { store, persistor };
