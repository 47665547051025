import React, { useState } from 'react';
import './Contact.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';
import { useDispatch } from 'react-redux';
import axiosInstance from '../axiosConfig';
import { hideLoading, showLoading } from '../store/loadingSlice';

function Contact() {
  const dispatch = useDispatch();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const [formData, setFormData] = useState({
    name: '',
    vat: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = 'Name is required';
    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email address is invalid';
    }
    if (!formData.phone) {
      formErrors.phone = 'Phone number is required';
    } else if (!/^\d+$/.test(formData.phone)) {
      formErrors.phone = 'Phone number must contain only digits';
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setErrors({});
    setIsSubmitting(true);

    try {
      dispatch(showLoading());
      
      const response = await axiosInstance.post("/mailer", formData);

      if (response.data.success) {
        alert('Form submitted successfully');
      } else {
        alert('Failed to submit. Please try again later.');
      }
      // Clear the form
      setFormData({
        name: '',
        vat: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      console.error('Error:', error);
      alert(error.message);
    } finally {
      dispatch(hideLoading());
      setIsSubmitting(false);
    }
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`container-contact ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Contact Us</h1>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="vat">VAT</label>
              <input
                type="text"
                id="vat"
                name="vat"
                value={formData.vat}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone*</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <p className="error">{errors.phone}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;