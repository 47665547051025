import React, {useState} from 'react';
import './Dashboard.css';
import NavBar from "../Navbar/Navbar.js"
import Header from '../Header/Header.js';
import { useUser } from '../UserContext.js'

const Dashboard = () => {

  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`dashboard-container ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        {/* Add any content here */}
      </div>
    </div>
  );
}

export default Dashboard;
