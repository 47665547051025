import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { useDispatch } from 'react-redux';
import { liscenceSlice } from '../Liscencing/LiscenceSlice';
import { checkLiscence } from '../Liscencing/CheckLiscence';
import { staticSlice } from '../store/staticSlice';
import './Login.css';
import { useTranslation } from "react-i18next";
import i18n from '../Utils/i18n'; // Import i18n instance
import { hideLoading, showLoading } from '../store/loadingSlice';
import Popup from 'reactjs-popup';

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [lan, setLan] = useState(localStorage.getItem('appLanguage') || 'gr');
  const { t } = useTranslation();
  const languages = [
    { value: 'gr', label: 'Greek' },
    { value: 'ro', label: 'Romanian' },
    { value: 'en', label: 'English' },
  ];

  const changeLanguage = (language) => {
    setLan(language);
    localStorage.setItem('appLanguage', language);
    i18n.changeLanguage(language);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(showLoading());
      const liscence = await checkLiscence(username);
      if (liscence.error) {
        alert(liscence.error_msg);
        return;
      }

      const servicePath = liscence.liscence.software_type === "TESAE" ? '/pegasus/a_xit/connector.php' : '/s1services';

      const requestBody = {
        url: `https://${liscence.liscence.company_url}${servicePath}`,
        service: 'login',
        username,
        password,
        appId: liscence.liscence.appid,
        COMPANY: liscence.liscence.company_id,
        BRANCH: liscence.liscence.company_branch,
        MODULE: '0',
        REFID: liscence.liscence.refid,
      };
      const response = await axiosInstance.post("/api", requestBody);

      if (response.data.success) {
        localStorage.setItem('token', response.data.clientID);
        localStorage.setItem('company', response.data.companyinfo.split(',')[0]);
        localStorage.setItem('username', username);
        
        dispatch(staticSlice.actions.setActor(requestBody.REFID));
        dispatch(liscenceSlice.actions.setModules(liscence.liscence));

        navigate('/dashboard');
      } else {
        setErrorMsg('Failed to login. Check your credentials!');
        alert('Failed to login. Check your credentials!');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMsg('Failed to login. Check your credentials!');
      alert('Failed to login. Check your credentials!');
    } finally {
      dispatch(hideLoading());
    }
  };
///<label htmlFor="username" className="form-label">{t("username")}</label>
///<label htmlFor="password" className="form-label">{t("password")}</label>
  
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="logo-container">
                <img src="/chronos_logo.jpg" width="75%" alt="Logo" className="logo" />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder={t("username")}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder={t("password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                      <button type="submit" className="btn btn-primary">{t("login")}</button>
                </div>
                      
                <Popup
                  position="top center"
                  trigger={

                    <button
                      type='button'
                      style={{
                        padding: '10px',
                        alignSelf: 'flex-end',
                        background: 'none',
                        border: 'none',
                      }}
                    >
                      <span
                        style={{
                          textAlign: 'right',
                          textDecoration: 'underline',
                          color: 'green',
                          fontWeight: 'bold',
                          fontSize: '15px',
                        }}
                      >
                        Change language
                      </span>
                    </button>
                  }>
                  <div
                    style={{
                      position: 'absolute',
                      top: '0px', // Adjust this value to position above or below the button
                      right: 0,
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      padding: '10px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      zIndex: 10,
                      width: '150px',
                      textAlign: 'center',
                    }}
                  >
                    {languages.map((lang) => (
                      <button
                        key={lang.value}
                        onClick={() => changeLanguage(lang.value)}
                        style={{
                          display: 'block',
                          width: '100%',
                          backgroundColor: lan === lang.value ? 'green' : 'white',
                          color: lan === lang.value ? 'white' : 'black',
                          border: 'none',
                          padding: '8px',
                          margin: '5px 0',
                        }}
                      >
                        {lang.label}
                      </button>
                    ))}
                  </div>
                </Popup>

                
                {errorMsg && <p className="text-danger">{errorMsg}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
