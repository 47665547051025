import { combineReducers } from 'redux';
import liscenceSlice from '../Liscencing/LiscenceSlice';
import staticSlice from './staticSlice';
import loadingReducer from './loadingSlice';

// Combine the reducers
const rootReducer = combineReducers({
  loading: loadingReducer, // For loader state
  liscence: liscenceSlice, // License slice
  static: staticSlice, // Static slice
  // Add other reducers here
});

export default rootReducer;