// src/axiosConfig.js
import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

axiosInstance.interceptors.request.use(
  function(config) {
    console.log('Request URL:', config.url);
    console.log('Request Headers:', config.headers);
    console.log('Request Data:', config.data);
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Optionally, you could add a response interceptor to handle or log responses globally
axiosInstance.interceptors.response.use(
  function(response) {
    console.log('Response Data:', response.data);
    return response;
  },
  function(error) {
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;