import React, { useEffect, useState } from 'react';
import NavBar from "../Navbar/Navbar.js";
import Header from '../Header/Header.js';
import './CusCard.css';
import { useUser } from '../UserContext';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../store/loadingSlice.js';
import axiosInstance from '../axiosConfig.js';
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';  // Import xlsx library

const KartelaPage = () => {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const licenseData = useSelector((state) => state.liscence.modules);
  const dispatch = useDispatch();
  const [kartela, setKartela] = useState([]);
  // PAGINATION
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagefrom, setPageFrom] = useState(1);
  const [pageto, setPageTo] = useState(10); 

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPageFrom((prev) => prev + 10);
      setPageTo((prev) => prev + 10);
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setPageFrom((prev) => Math.max(1, prev - 10));
      setPageTo((prev) => Math.max(10, prev - 10));
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    getKartela(pagefrom, pageto);
  }, [pagefrom, pageto]);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const getKartela = async () => {
    try {
      dispatch(showLoading());

      const thisClientID = localStorage.getItem("token");
      const parameters = {
        url: `https://${licenseData.company_url}/s1services`,
        service: "SqlData",
        clientID: thisClientID,
        appId: "1001",
        SqlName: "9502",
        company: licenseData.company_id,
        afm: licenseData.afm,
        pagefrom: String(pagefrom),
        pageto: String(pageto),
      };

      const response = await axiosInstance.post("/api", parameters);
      if (response && response.data) {
        setKartela(response.data.rows);
        
        // Assuming rowcount1 is part of each row returned
        const totalRows = response.data.rows[0]?.ROWCOUNT1 || 0;
        setTotalPages(totalRows > 0 ? Math.ceil(totalRows / 10) : 1);

      } else {
        setKartela([]); // Fallback in case of unexpected response
      }
    } catch (error) {
      console.error("Error fetching card:", error);
      alert(t("errorFetchingData"));
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getKartela();
  }, []);

  // Export to Excel function
  const exportToExcel = () => {
    // Get table headers
    const tableHeaders = Array.from(document.querySelectorAll('.table th')).map(
      th => th.innerText
    );
    // Convert kartela data to a worksheet with headers
    const dataWithHeaders = [tableHeaders, ...kartela.map(row => [
      row.ROWINDEX || '-',
      row.ID || '-',
      row.FINCODEID || '-',
      row.TRNDATE || '-',
      row.DEBIT || '-',
      row.CREDIT || '-',
      row.progressive_balance || '-',
    ])];
    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DataExport");
    // Generate an Excel file and trigger download
    XLSX.writeFile(workbook, t("DailyCustomerCard")+".xlsx");
  };

  return (
    <body id="module8">
      <div className={`container-card ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
        <NavBar toggleNav={toggleNav} />
        <div className="dashboard-content">
          <Header username={username} companyName={companyName} />
          <div className="main-content">
            <h1>{t("DailyCustomerCard")}</h1>
            <button onClick={exportToExcel} className="export-button">
              {t("exportToExcel")}
            </button>
              <div className="table-container">
                  <table className="table">
                      <thead>
                          <tr>
                              <th>{t("AA")}</th>
                              <th>{t("Date")}</th>
                              <th>{t("DocumentCode")}</th>
                              <th>{t("Reasoning")}</th>
                              <th>{t("Debit")}</th>
                              <th>{t("Credit")}</th>
                              <th>{t("progressiveBalance")}</th>
                          </tr>
                      </thead>
                      <tbody> 
                       {Array.isArray(kartela) && kartela.length > 0 ? (
                          kartela.map((card, index) => (
                              <tr key={card.ID || index}>
                                  <td>{card.ROWINDEX || '-'}</td>
                                  <td>{card.TRNDATE || '-'}</td>
                                  <td>{card.FINCODEID || '-'}</td>
                                  <td>{card.COMMENTS || '-'}</td>
                                  <td>{card.DEBIT || '-'}</td>
                                  <td>{card.CREDIT || '-'}</td>
                                  <td>{card.progressive_balance || '-'}</td>
                              </tr>
                          ))
                       ) : (
                          <tr>
                            <td colSpan="7">{t("noData")}</td>
                          </tr>
                       )}
                      </tbody>
                  </table>
                  <div className="pagination-buttons">
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                      {t("Previous")}
                    </button>
                    <span className="paginationSpan">{t("Page")} {currentPage} {t("outOf")} {totalPages}</span>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                      {t("Next")}
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </body>
  );
};

export default KartelaPage;