import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modules: {
    company_id: '',
    company_branch: '',
    company_url: '',
    modules: '',
    afm: '',
    refid: '',
    appid: '',
  },
};

export const liscenceSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules.company_id = action.payload.company_id;
      state.modules.company_branch = action.payload.company_branch;
      state.modules.company_url = action.payload.company_url;
      state.modules.modules = action.payload.modules;
      state.modules.afm = action.payload.afm;
      state.modules.refid = action.payload.refid;
      state.modules.appid = action.payload.appid;
    },
    
    clear: (state) => {
      state.modules.company_id = '';        
      state.modules.company_branch = '';    
      state.modules.company_url = '';    
      state.modules.modules = '';
      state.modules.afm = '';
      state.modules.refid = '';
      state.modules.appid = '';
    },
  },
});

export const { setModules, clear } = liscenceSlice.actions; 
export default liscenceSlice.reducer;
