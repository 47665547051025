import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  static: {
    actor: ''
  },
};

export const staticSlice = createSlice({
  name: 'static',
  initialState: initialState,
  reducers: {
    setActor: (state, action) => {
      state.static.actor = action.payload;
    },
    clear: (state) => {
      state.static.actor = '';
    },
  },
});

export const { setActor, clear } = staticSlice.actions;
export default staticSlice.reducer;