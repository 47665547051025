import React, { useState } from 'react';
import './Profile.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';
import { useSelector } from 'react-redux';

function Profile() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`container-contact ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Profile View</h1>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name: </label><h4>{username || ""}</h4>
            </div>
            <div className="form-group">
              <label htmlFor="name">Company Name: </label><h4>{companyName || ""}</h4>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
