import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      login: "Login",
      domain: "Domain",
      username: "Username",
      password: "Password",
      hello: "Hello",
      invoices: "Invoices",
      fromDate: "From Date",
      toDate: "To Date",
      applyFilters: "Apply Filters",
      exportToExcel: "Export to Excel",
      previous: "Previous",
      next: "Next",
      home: "Home",
      loading: "Loading...",
      logout: "Logout",
      dashboard: "Dashboard",
      fortotikes: "Fortotikes List",
      uploadFortotikes: "Upload Fortotikes",
      uploadInvoice: "Upload Invoice",
      tracking: "Tracking",
      pins: "Pins",
      contact: "Contact",
      statistics: "Statistics",
      exit: "Exit",
      errorFetchingData: "Error fetching data",
      noRecordsFound: "No records found",
      dataSavedSuccessfully: "Data saved successfully",
      deleteConfirmation: "Are you sure you want to delete this item?",
      page: "page",
      select: "Select",
      confirmLogout: "Are you sure you want to log out?",
      loggedOut: "You have successfully logged out.",
      //fortotiki list:
      entryDate: "Document Date",
      DocumentCode: "Document Code",
      CustomerDoc: "Customer Code",
      SenderName: "Sender Name",
      RecieverName: "Reciever Name",
      DeliveryAddress: "Address",
      DeliveryCity: "City",
      DeliveryStatus: "Status",
      CancellationReason: "Cancellation Reason",
      DeliveryRemarks: "Remarks",
      //kartela pelati
      CusCard: "Customer Card",
      DailyCustomerCard: "Daily Customer Card",
      Date: "Date",
      Debit: "Debit",
      Credit: "Credit",
      progressiveBalance: "Balance",
      noData: "No Data Available",
      Previous: "Previous",
      Next: "Next",
      Page: "Page",
      outOf: "Out of",
      Reasoning: "Reasoning ",
      errorDownloadingDocument: "Error downloading document",
      action: "Action",
    },
  },
  gr: {
    translation: {
      login: "Σύνδεση",
      domain: "Σύνδεσμος",
      username: "Όνομα χρήστη",
      password: "Κωδικός πρόσβασης",
      hello: "Γειά σου",
      invoices: "Τιμολόγια",
      fromDate: "Από Ημερομηνία",
      toDate: "Μέχρι Ημερομηνία",
      applyFilters: "Εφαρμογή Φίλτρων",
      exportToExcel: "Εξαγωγή σε Excel",
      previous: "Προηγούμενη",
      next: "Επόμενη",
      home: "Αρχική",
      loading: "Φόρτωση...",
      logout: "Έξοδος",
      dashboard: "Πίνακας ελέγχου",
      fortotikes: "Λίστα Φορτωτικών",
      uploadFortotikes: "Φόρτωση Φορτωτικών",
      uploadInvoice: "Ανέβασμα Τιμολογίου",
      tracking: "Παρακολούθηση",
      pins: "Πυλώνες",
      contact: "Επικοινωνία",
      statistics: "Στατιστικά",
      exit: "Έξοδος",
      errorFetchingData: "Σφάλμα κατά την λήψη δεδομένων",
      noRecordsFound: "Δεν βρέθηκαν εγγραφές",
      dataSavedSuccessfully: "Τα δεδομένα αποθηκεύτηκαν με επιτυχία",
      deleteConfirmation: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το στοιχείο;",
      page: "Σελίδα",
      select: "Επιλέξτε",
      confirmLogout: "Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε;",
      loggedOut: "Έχετε αποσυνδεθεί επιτυχώς.",
      //fortotiki list:
      entryDate: "Ημερ.Παραστατικού",
      DocumentCode: "Παραστατικό",
      CustomerDoc: "Παραστατικό Πελάτη",
      SenderName: "Επωνυμία Αποστολέα",
      RecieverName: "Επωνυμία Παραλήπτη",
      DeliveryAddress: "Διεύθ.Παράδοσης",
      DeliveryCity: "Πόλη Παράδοσης",
      DeliveryStatus: "Κατάσταση",
      CancellationReason: "Αιτία Ακύρωσης",
      DeliveryRemarks: "Παρατηρήσεις",
      //kartela pelati
      CusCard: "Καρτέλα Πελάτη",
      DailyCustomerCard: "Ημερήσια Καρτέλα Πελάτη",
      Date: "Ημ/νία",
      Debit: "Χρέωση",
      Credit: "Πίστωση",
      progressiveBalance: "Υπόλοιπο",
      noData: "Δεν υπάρχουν διαθέσιμα δεδομένα",
      Previous: "Προηγούμενη",
      Next: "Επόμενη",
      Page: "Σελίδα",
      outOf: "Από τις",
      Reasoning: "Αιτιολογία ",
      errorDownloadingDocument: "Σφάλμα κατά τη λήψη του εγγράφου",
      action: "Ενέργεια",
    },
  },
  ro: {
    translation: {
      login: "Log in",
      domain: "Domeniu",
      username: "Nume de utilizator",
      password: "Parola",
      hello: "Buna ziua",
      invoices: "Facturi",
      fromDate: "Data de început",
      toDate: "Data de sfârșit",
      applyFilters: "Aplică filtrele",
      exportToExcel: "Exportă în Excel",
      previous: "Anterior",
      next: "Următor",
      home: "Acasă",
      loading: "Se încarcă...",
      logout: "Deconectare",
      dashboard: "Tabloul de bord",
      fortotikes: "Lista Fortotikes",
      uploadFortotikes: "Încărcare Fortotikes",
      uploadInvoice: "Încărcați Factura",
      tracking: "Urmărire",
      pins: "Pini",
      contact: "Contact",
      statistics: "Statistici",
      exit: "Ieșire",
      errorFetchingData: "Eroare la obținerea datelor",
      noRecordsFound: "Nu au fost găsite înregistrări",
      dataSavedSuccessfully: "Datele au fost salvate cu succes",
      deleteConfirmation: "Ești sigur că vrei să ștergi acest element?",
      page: "pagină",
      select: "Selectați",
      confirmLogout: "Sunteți sigur că doriți să vă deconectați?",
      loggedOut: "Te-ai deconectat cu succes.",
      //fortotiki list:
      entryDate: "data documentului",
      DocumentCode: "document",
      CustomerDoc: "document client",
      SenderName: "Nume expeditor",
      RecieverName: "Numele destinatarului",
      DeliveryAddress: "Adresa de livrare",
      DeliveryCity: "Orașul de livrare",
      DeliveryStatus: "Situaţie",
      CancellationReason: "Motivul anulării",
      DeliveryRemarks: "Note de distribuție",
      //kartela pelati
      CusCard: "Cardul clientului",
      DailyCustomerCard: "Cardul zilnic al clientului",
      Date: "Data",
      Debit: "Debit",
      Credit: "Credit",
      progressiveBalance: "Progresiv",
      noData: "Nu există date disponibile",
      Previous: "Anterior",
      Next: "Următorul",
      Page: "Pagină",
      outOf: "De la",
      Reasoning: "rațiune",
      errorDownloadingDocument: "Eroare la descărcarea documentului",
      action: "Acțiune",
    },
  },
};

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (callback) => {
    const storedLanguage = localStorage.getItem("appLanguage"); 
    callback(storedLanguage || "gr"); // Default to 'gr' if not found
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    localStorage.setItem("appLanguage", lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
