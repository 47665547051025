import React, {useState} from 'react';
import { Bar, Pie, Line, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import "./Statistics.css"
import Navbar from "../Navbar/Navbar.js"

// Registering necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);


const barData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: 'Ολοκληρωμένες Φορτωτικές ανα Μήνα',
      data: [65, 59, 80, 81, 56, 55, 40, 60, 70, 75, 90, 100],
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)',
        'rgba(199, 199, 199, 0.5)',
        'rgba(83, 102, 255, 0.5)',
        'rgba(40, 159, 44, 0.5)',
        'rgba(255, 99, 132, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(54, 162, 235, 0.5)'
      ],
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderWidth: 1,
    }
  ],
};

const pieData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: 'Μηνιαία Κόμιστρα',
      data: [1200, 1900, 750, 950, 1000, 1170, 1250, 1300, 900, 850, 950, 1000],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(199, 199, 199, 0.7)',
        'rgba(83, 102, 255, 0.7)',
        'rgba(40, 159, 44, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(54, 162, 235, 0.7)'
      ],
      hoverOffset: 4
    }
  ],
};

const lineData = {
    labels: ['2015', '2016', '2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'Μεσοσταθμική Τάση Φορτωτικών',
        data: [600, 700, 800, 900, 1000, 1100, 1200],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: true,
      }
    ],
  };

  const doughnutData = {
    labels: ['Maintenance', 'Salaries', 'Utilities', 'Other'],
    datasets: [
      {
        label: 'Κόστος Φορτωτικών',
        data: [300, 500, 200, 100],
        backgroundColor: ['red', 'blue', 'green', 'purple'],
        hoverOffset: 4,
      }
    ],
  };

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'index',
      intersect: false,
    },
  },
  scales: {
    x: {  // For the Bar chart, explicitly define the scale type for the x-axis
      type: 'category',
    },
    y: {  // Define the scale type for the y-axis
      type: 'linear',
      beginAtZero: true
    }
  }
};

function StatisticsPage() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const toggleNav = () => {
  setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="statistics-page">
      <div className="navbar-left-stats">
        <Navbar toggleNav={toggleNav} />
      </div>
      <div className="content-area">
        <h1>Statistics Dashboard</h1>
        <div className="chart-container">
          <div className="chart">
            <h2>Ολοκληρωμένες Φορτωτικές ανα Μήνα</h2>
            <Bar data={barData} options={options} />
          </div>
          <div className="chart">
            <h2>Μηνιαία Κόμιστρα</h2>
            <Pie data={pieData} options={options} />
          </div>
          <div className="chart">
            <h2>Μεσοσταθμική Τάση Φορτωτικών</h2>
            <Line data={lineData} options={options} />
          </div>
          <div className="chart">
            <h2>Κόστος Φορτωτικών</h2>
            <Doughnut data={doughnutData} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
}


export default StatisticsPage;
