import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [username, setUsername] = useState(localStorage.getItem('username') || 'Default User');
  const [companyName, setCompanyName] = useState(localStorage.getItem('company') || 'Default Company');

  useEffect(() => {
    localStorage.setItem('username', username);
    localStorage.setItem('company', companyName);
  }, [username, companyName]);

  return (
    <UserContext.Provider value={{ username, companyName, setUsername, setCompanyName }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
