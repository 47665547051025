import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import axiosInstance from '../axiosConfig';
import './Upload.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../store/loadingSlice';

function Upload() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null); // Create a ref for the file input
  const licenseData = useSelector((state) => state.liscence.modules);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const isDate = (value) => {
    return typeof value === 'number' && value > 0 && value < 50000;
  };

  const formatDate = (excelDate) => {
    const date = new Date((excelDate - 25569) * 86400 * 1000);
    return date.toISOString().slice(0, 19).replace('T', ' ');
  };

  const handleReset = (event) => {
    setData([])
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleDataUpload = async () => {
    try {

      dispatch(showLoading());

      const thisClientID = localStorage.getItem("token");

      const requestBody = {
        url: `https://${licenseData.company_url}/s1services`,
        service: "setData",
        clientID: thisClientID,
        appId: "1001",
        OBJECT: "CCCXITOWEBAPPIMPORT",
        KEY: "",
        data: {
          CCCXITWEBAPPIMPORT: data.map(item => ({
            VOUCHER: item.VOUCHER.toString(),
            STATUS: item.STATUS,
            DELIVERYDATE: isDate(item.DELIVERYDATE) ? formatDate(item.DELIVERYDATE) : item.DELIVERYDATE,
            INSERTDATE: isDate(item.INSERTDATE) ? formatDate(item.INSERTDATE) : item.INSERTDATE,
            SENDER: item.SENDER,
            RECIEVER: item.RECIEVER,
            CUSTOMER: item.CUSTOMER,
            DELIVERYADDRESS: item.DELIVERYADDRESS,
            DELIVERYPHONE: item.DELIVERYPHONE.toString(),
            DELIVERYZIP: item.DELIVERYZIP.toString(),
            DELIVERYCITY: item.DELIVERYCITY,
            CUSTOMERAFM: item.CUSTOMERAFM.toString(),
            VALUE: item.VALUE.toString(),
            COD: item.COD.toString(),
            INSUSER: item.INSUSER
          }))
        }
      };

      const response = await axiosInstance.post("/api", requestBody);
      if (response.data.success) {
        alert('Data uploaded successfully!');
      } else {
        alert(`Error uploading data: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error uploading data:', error);
      alert('Error uploading data!');
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div className={`container-upload ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Upload Excel File</h1>
          <input type="file" className='form-control' accept=".xlsx, .xls" onChange={handleFileUpload} ref={fileInputRef} />
          {data.length > 0 && (
            <>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      {Object.keys(data[0]).map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, i) => (
                          <td key={i}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mb-4'>
                <button onClick={handleDataUpload} className="btn btn-primary m-3">
                  Upload Data
                </button>
                <button onClick={handleReset} type='reset' className="btn btn-secondary">
                  Reset
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Upload;