/* global google */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMap, Polyline, InfoWindow, useJsApiLoader, Marker } from '@react-google-maps/api';
import axiosInstance from '../axiosConfig.js';
import Navbar from "../Navbar/Navbar.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './CreatePin2.css';
import { hideLoading, showLoading } from '../store/loadingSlice.js';
import { useDispatch, useSelector } from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const MapWithMarkers = () => {
  const dispatch = useDispatch();
  const [markerData, setMarkerData] = useState([]);
  const [center] = useState({ lat: 37.983810, lng: 23.727539 });
  const mapRef = useRef(null);
  const licenseData = useSelector((state) => state.liscence.modules);
  ///const markerElementsRef = useRef([]); // Store AdvancedMarkerElements

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['marker']  // Load the marker library for AdvancedMarkerElement
  });

  // Sidebar and Form State
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [name, setName] = useState('');
  const [EmprAnoigma, setEmprAnoigma] = useState('');
  const [XilThesi, setXilThesi] = useState('');
  const [Arekmet, setArekmet] = useState('');
  const [Skyrvasis, setSkyrvasis] = useState('');
  const [Anegkormou, setAnegkormou] = useState('');
  const [ProsdesiAF, setProsdesiAF] = useState('');
  const [ProsdesiAP, setProsdesiAP] = useState('');
  const [ProergEktyl, setProergEktyl] = useState('');
  const [EktyliksiAF, setEktyliksiAF] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [errors, setErrors] = useState({});

  // Polyline and Pin Selection State
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const [isConnecting, setIsConnecting] = useState(false);  // Line creation mode toggle

  // Toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
    if (!isSidebarVisible) resetForm();
  };

  const resetForm = () => {
    setLat('');
    setLng('');
    setName('');
    setEmprAnoigma('');
    setXilThesi('');
    setArekmet('');
    setSkyrvasis('');
    setAnegkormou('');
    setProsdesiAF('');
    setProsdesiAP('');
    setProergEktyl('');
    setEktyliksiAF('');
    setIsUpdate(false);
    setErrors({});
    setSelectedMarker(null);
  };

  const loadPins = async () => {
    dispatch(showLoading());
    setPolylines([]);
    setMarkerData([]);
    try {
      const thisClientID = localStorage.getItem("token");
      const parameters = {
        url: `https://${licenseData.company_url}/s1services`,
        service: "SqlData",
        clientID: thisClientID,
        appId: "1001",
        SqlName: "7100"
      };
      const resp = await axiosInstance.post("/api", parameters);
      if (resp?.data.success) {
        setMarkerData(resp.data.rows);
        console.log("data coming from softone",resp.data);
      } else {
        alert("Error fetching markers1:", resp?.error);
      }
    } catch (error) {
      alert("Error fetching markers2:", error);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    loadPins();
  }, []);

useEffect(() => {
  if (markerData.length > 0) {
    const initialPolylines = [];
    markerData.forEach((marker) => {
      if (marker.LINETO) {
        const linkedMarker = markerData.find(m => m.prjc === marker.LINETO);
        if (linkedMarker) {
          initialPolylines.push([marker, linkedMarker]);
        }
      }
    });
    setPolylines(initialPolylines);
  }
}, [markerData]);

  // useEffect(() => {
  //   if (isLoaded && mapRef.current && markerData.length) {
  //     // Clear existing markers
  //     markerElementsRef.current.forEach(marker => marker.setMap(null));

  //     markerElementsRef.current = markerData.map((marker) => {
  //       const lat = parseFloat(marker.latitude);
  //       const lng = parseFloat(marker.longitude);

  //       console.log(`Creating marker at lat: ${lat}, lng: ${lng}`); // Debugging log

  //       const markerElement = new window.google.maps.marker.AdvancedMarkerElement({
  //         map: mapRef.current,
  //         position: { lat, lng },
  //         title: marker.name,
  //       });

  //       markerElement.addListener("click", () => handleMarkerClick(marker));
  //       markerElement.addListener("dblclick", () => handleMarkerSelectForLine(marker));

  //       return markerElement;
  //     });
  //   }
  // }, [markerData, isLoaded]);

  useEffect(() => {
    console.log("isConnecting changed to:", isConnecting);
  }, [isConnecting]);

  const handleMarkerClick = (marker) => {
    console.log("isConnecting changed to:", isConnecting);
    if (isConnecting) {
      // In line creation mode, select markers for connection
      handleMarkerSelectForLine(marker);
    } else {
      // Normal mode: show update form
      if (selectedMarker?.prjc !== marker.prjc) {
        setSelectedMarker(marker);
        setLat(marker.latitude);
        setLng(marker.longitude);
        setName(marker.name);
        setEmprAnoigma(marker.EMPRANOIGMA);
        setXilThesi(marker.XILTHESI);
        setArekmet(marker.arekmet); // Set the arekmet value
        setSkyrvasis(marker.SKYRVASIS);
        setAnegkormou(marker.ANEGKORMOY);
        setProsdesiAF(marker.PROSDESIAF);
        setProsdesiAP(marker.PROSDESIAP);
        setProergEktyl(marker.PROERGEKTYL);
        setEktyliksiAF(marker.EKTYLIKSIAF);
        setIsUpdate(true);
        setErrors({});
        setIsSidebarVisible(true);
      }
    }
  };

  const handleMapClick = (event) => {
    if (!isConnecting) {  // Only enable map click for normal mode
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setLat(lat);
      setLng(lng);
      setName("");
      setEmprAnoigma("");
      setXilThesi("");
      setArekmet("");
      setSkyrvasis("");
      setAnegkormou("");
      setProsdesiAF("");
      setProsdesiAP("");
      setProergEktyl("");
      setEktyliksiAF("");
      setIsUpdate(false);
      setIsSidebarVisible(true);
      setSelectedMarker(null);
    }
  };
  
  const handleMarkerSelectForLine = useCallback((marker) => {
    setSelectedMarkers((current) => {
      if (current.length === 2) {
        const newSelected = [marker];
        setPolylines((currentLines) => currentLines.filter(
          (line) => !line.every((m) => current.includes(m)) 
        ));
        return newSelected;
      }
  
      const newSelected = [...current, marker];
      if (newSelected.length === 2) {
        setPolylines((currentLines) => [...currentLines, newSelected]);
        return newSelected;
      }
      return newSelected;
    });
  }, [polylines]);

  const startConnecting = () => {
    setIsConnecting(true);
    setSelectedMarkers([]); // Reset any previous selection
  };

  const saveLineConnection = async () => {
    if (selectedMarkers.length === 2) {
      dispatch(showLoading());

      const [pin1, pin2] = selectedMarkers;
      const requestBody = {
        url: `https://${licenseData.company_url}/s1services`,
        service: "setData",
        clientID: localStorage.getItem("token"),
        appId: "1001",
        OBJECT: "PRJC",
        KEY: pin1.prjc,
        data: {
          PRJEXTRA: [{ VARCHAR03: pin2.prjc }],
        },
      };

      try {
        const response = await axiosInstance.post("/api", requestBody);
        if (response.data.success) {
          alert('Pins connected successfully!');
          setIsConnecting(false);
          setSelectedMarkers([]);
          await loadPins(); 
        } else {
          alert("Error connecting pins!", response.data.error);
        }
      } catch (error) {
        console.error("Error connecting pins!", error);
        alert("Error connecting pins!", error);
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  const cancelLineConnection = () => {
    setIsConnecting(false);
    loadPins();
  };

  const validateForm = () => {
    const errors = {};
    if (!lat) errors.lat = 'Latitude is required';
    if (!lng) errors.lng = 'Longitude is required';
    if (!name) errors.name = 'Name is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveOrUpdatePin = async (event, isDelete = false) => {
    event.preventDefault();
    if (!validateForm() && !isDelete) return;

    dispatch(showLoading());

    const requestBody = {
      url: `https://${licenseData.company_url}/s1services`,
      service: "setData",
      clientID: localStorage.getItem("token"),
      appId: "1001",
      OBJECT: "PRJC",
      KEY: isUpdate ? selectedMarker.prjc : "",
      data: {
        PRJC: [
          {
            CODE: isUpdate ? selectedMarker.code : name,
            NAME: name,
            PRJCATEGORY: "1",
            XILTHESI: XilThesi,
            ISACTIVE: isDelete ? 0 : 1
          }
        ],
        PRJEXTRA: [
          { VARCHAR01: lat.toString(), VARCHAR02: lng.toString(), UTBL01: "A" }
        ]
      }
    };

    try {
      const response = await axiosInstance.post("/api", requestBody);
      alert(response.data.success ? (isDelete ? 'Pin deleted' : isUpdate ? 'Pin updated' : 'Pin created') : 'Error saving/deleting pin');
      loadPins();
      toggleSidebar();
    } catch (error) {
      console.error('Error saving/deleting pin:', error);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div className='container-pin-create'>
      <Navbar />
      <div className="map">
        {isLoaded && (
          <GoogleMap
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
            onClick={handleMapClick}
            onLoad={map => {
              mapRef.current = map;
              map.set('mapId', process.env.REACT_APP_GOOGLE_MAPS_API_KEY); // Set your Map ID here
            }}
          >
            {markerData.map((marker, index) => (
              <Marker
              key={index}
              position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
              onClick={() => handleMarkerClick(marker)}
              onDblClick={() => handleMarkerSelectForLine(marker)}
              ///icon={pylonIconSvg(marker.COLOR)}
              label={{
                text: marker.arekmet ? marker.arekmet.toString() : "", // Convert arekmet to string
                color: "#000", // Label text color
                fontSize: "12px", // Label font size
                fontWeight: "bold", // Label font weight
              }}
              icon={{
                url: `/` + marker.COLOR.replace(/.*\\public\\/g, ""), // Construct relative URL for the marker image
                scaledSize: new window.google.maps.Size(40, 40), // Adjust marker size
                anchor: new window.google.maps.Point(20, 20), // Set anchor to center (half of width and height)
                labelOrigin: new window.google.maps.Point(20, -10), // Label positioned above the marker
              }}
              >
                {selectedMarker === marker && (
                  <InfoWindow
                    position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <div>
                      <strong>{marker.name}</strong><br />
                      Lat: {parseFloat(marker.latitude).toFixed(4)}, Lng: {parseFloat(marker.longitude).toFixed(4)}
                      <br />
                      Arekmet: {marker.arekmet}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
            {polylines.map((line, index) => {
              const grammi = line[0]?.GRAMMI; // Assuming GRAMMI is on the first marker of the line
              let strokeColor = '#8f8585';   // Default color

              // Set the stroke color based on the GRAMMI value
              if (grammi === 'laxani') {
                strokeColor = '#d5e2b7';
              } else if (grammi === 'prasino') {
                 strokeColor = '#00af4d';
              }

              return (
                <Polyline
                key={index}
                path={line.map((marker) => ({
                  lat: parseFloat(marker.latitude),
                  lng: parseFloat(marker.longitude),
                }))}
                options={{
                  strokeColor: strokeColor,  // Dynamically set stroke color
                  strokeOpacity: 1.0,       // Line opacity
                  strokeWeight: 2,          // Line thickness
                }}
              />
              );
            })}
          </GoogleMap>
        )}
      </div>

      {!isSidebarVisible && (
        <div className='create-btn-row'>
          {!isConnecting ? (
            <>
              <button className="btn-create btn-create-pin" onClick={toggleSidebar}>Create Pin</button>
              <button className="btn-create btn-create-line" onClick={startConnecting}>Create Line</button>
            </>
          ) : (
            <>
              <button className="btn-create btn-save-line" onClick={saveLineConnection}>Save Line</button>
              <button className="btn-cancel btn-cancel-line" onClick={cancelLineConnection}>Cancel</button>
            </>
          )}
        </div>
      )}

      {isSidebarVisible && (
        <div className="controls-pin-create">
          <button className="close-sidebar" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2>{isUpdate ? 'Update Pin' : 'Create Pin'}</h2>
          <p className="input-group">Latitude</p>
          <input className='input-pin-text' type="text" placeholder="Latitude" value={lat} onChange={(e) => setLat(e.target.value)} />
          {errors.lat && <div className="error">{errors.lat}</div>}

          <p className="input-group">Longitude</p>
          <input className='input-pin-text' type="text" placeholder="Longitude" value={lng} onChange={(e) => setLng(e.target.value)} />
          {errors.lng && <div className="error">{errors.lng}</div>}

          <p className="input-group">Τίτλος Έργου</p>
          <input className='input-pin-text' type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
          {errors.name && <div className="error">{errors.name}</div>}

          <p className="input-group">Έμπροσθεν Άνοιγμα</p>
          <input id="EmprAnoigma" className="input-pin-text" type="text" placeholder="Έμπροσθεν Άνοιγμα" value={EmprAnoigma} onChange={(e) => setEmprAnoigma(e.target.value)} />
          {errors.EmprAnoigma && <div className="error">{errors.EmprAnoigma}</div>}
          
          <p className="input-group">Χιλιομετρική Θέση</p>
          <input id="xilThesi" className="input-pin-text" type="text" placeholder="Χιλιομετρική Θέση" value={XilThesi} onChange={(e) => setXilThesi(e.target.value)} />
          {errors.XilThesi && <div className="error">{errors.XilThesi}</div>}

          <p className="input-group">Σκυροδέτηση Βάσης</p>
          <input id="Skyrvasis" className="input-pin-text" type="text" placeholder="Σκυροδέτηση Βάσης" value={Skyrvasis} onChange={(e) => setSkyrvasis(e.target.value)} />
          {errors.Skyrvasis && <div className="error">{errors.Skyrvasis}</div>}

          <p className="input-group">Ανέγερση πύργου</p>
          <input id="Anegkormou" className="input-pin-text" type="text" placeholder="Ανέγερση πύργου" value={Skyrvasis} onChange={(e) => setAnegkormou(e.target.value)} />
          {errors.Anegkormou && <div className="error">{errors.Anegkormou}</div>}

          <p className="input-group">Εξάρτηση/ πρόσδεση Αγωγού Φάσης</p>
          <input id="ProsdesiAF" className="input-pin-text" type="text" placeholder="Εξάρτηση/ πρόσδεση Αγωγού Φάσης" value={ProsdesiAF} onChange={(e) => setProsdesiAF(e.target.value)} />
          {errors.ProsdesiAF && <div className="error">{errors.ProsdesiAF}</div>}

          <p className="input-group">Εξάρτηση/ πρόσδεση Αγωγού Προστασίας (SW/OPGW)</p>
          <input id="ProsdesiAP" className="input-pin-text" type="text" placeholder="Εξάρτηση/ πρόσδεση Αγωγού Προστασίας (SW/OPGW)" value={ProsdesiAP} onChange={(e) => setProsdesiAP(e.target.value)} />
          {errors.ProsdesiAP && <div className="error">{errors.ProsdesiAP}</div>}

          <p className="input-group">Προεργασία εκτύλιξης αγωγών</p>
          <input id="ProergEktyl" className="input-pin-text" type="text" placeholder="Προεργασία εκτύλιξης αγωγών" value={ProergEktyl} onChange={(e) => setProergEktyl(e.target.value)} />
          {errors.ProergEktyl && <div className="error">{errors.ProergEktyl}</div>}

          <p className="input-group">Εγκατάσταση Α.Φ.</p>
          <input id="EktyliksiAF" className="input-pin-text" type="text" placeholder="Εγκατάσταση Α.Φ." value={EktyliksiAF} onChange={(e) => setEktyliksiAF(e.target.value)} />
          {errors.EktyliksiAF && <div className="error">{errors.EktyliksiAF}</div>}

          <button className="btn-savepin" onClick={(e) => saveOrUpdatePin(e, false)}>
            {isUpdate ? 'Update Pin' : 'Create Pin'}
          </button>

          {isUpdate && (
            <button className="btn-deletepin" onClick={(e) => saveOrUpdatePin(e, true)}>
              Delete Pin
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MapWithMarkers;
