import React, { useState } from 'react';
import './Tracking.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';

function Tracking() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const [trackingId, setTrackingId] = useState('');
  const [trackingDetails, setTrackingDetails] = useState([]);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleTrack = () => {
    // Replace this with actual API call
    const fakeData = [
      { datetime: '2024-01-01 12:00', state: 'In Transit', longitude: '23.7275', latitude: '37.9838', drivername: 'John Doe', trackingNr: trackingId },
      { datetime: '2024-01-02 14:00', state: 'Delivered', longitude: '23.7275', latitude: '37.9838', drivername: 'John Doe', trackingNr: trackingId },
    ];
    setTrackingDetails(fakeData);
  };

  const handleUndo = () => {
    setTrackingId('');
    setTrackingDetails([]);
  };

  return (
    <div className={`container-tracking ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Tracking</h1>
          <div className="tracking-input">
            <input
              type="text"
              placeholder="TRACKING ID"
              value={trackingId}
              onChange={(e) => setTrackingId(e.target.value)}
              className="tracking-input-field"
            />
            <button onClick={handleTrack} className="tracking-button">TRACK</button>
            {trackingDetails.length > 0 && (
              <button onClick={handleUndo} className="undo-button">UNDO</button>
            )}
          </div>
          {trackingDetails.length > 0 && (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date Time</th>
                    <th>State</th>
                    <th>Longitude</th>
                    <th>Latitude</th>
                    <th>Driver Name</th>
                    <th>Tracking Nr</th>
                  </tr>
                </thead>
                <tbody>
                  {trackingDetails.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail.datetime}</td>
                      <td>{detail.state}</td>
                      <td>{detail.longitude}</td>
                      <td>{detail.latitude}</td>
                      <td>{detail.drivername}</td>
                      <td>{detail.trackingNr}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tracking;
