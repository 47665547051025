import React, { useEffect, useRef, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { IoMdHelp, IoMdLogOut, IoMdPerson, IoMdSettings } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import i18n from '../Utils/i18n'; // Import i18n instance
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { staticSlice } from '../store/staticSlice';
import { liscenceSlice } from '../Liscencing/LiscenceSlice';
import { useDispatch } from 'react-redux';

const Header = ({ username, companyName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [lan, setLan] = useState(localStorage.getItem('appLanguage') || 'gr');
  const languages = [
    { value: 'gr', label: 'Greek' },
    { value: 'ro', label: 'Romanian' },
    { value: 'en', label: 'English' },
  ];
  const dropdownRef = useRef(null); 

  const changeLanguage = (language) => {
    setLan(language);
    localStorage.setItem('appLanguage', language);
    i18n.changeLanguage(language);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleLogout = () => {
    const confirmed = window.confirm(t("confirmLogout"));
    if (confirmed) {
      localStorage.clear();
      dispatch(staticSlice.actions.clear());
      dispatch(liscenceSlice.actions.clear());
      navigate('/login');
      alert(t("loggedOut"));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false); // Dismiss dropdown if clicked outside
      }
    };
  }, []);

  return (
    <div className="header d-flex justify-content-between align-items-center p-3">
      <div className="company-name text-white">{companyName}</div>

      <div className="action" ref={dropdownRef}>
        <div
          className="text-white d-flex align-items-center"
          onClick={toggleDropdown}>
          <FaUserCircle className="user-icon mr-2" />
          <span>{username}</span>
        </div>

        {isDropdownVisible && (
          <div className={`menu ${isDropdownVisible ? 'active' : ''}`}>
            <ul>
              <li>
                <IoMdPerson className="icon" />
                <Link to="/profile">My Profile</Link>
              </li>
              <li>

                <Popup
                  position="top left"
                  trigger={
                    <div>
                      <IoMdSettings className="icon" />
                      <Link to="">Change Language</Link>
                    </div>
                  }>
                  <div
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: 5,
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      padding: '10px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      zIndex: 10000,
                      width: '150px',
                      textAlign: 'center',
                    }}
                  >
                    {languages.map((lang) => (
                      <button
                        key={lang.value}
                        onClick={() => changeLanguage(lang.value)}
                        style={{
                          display: 'block',
                          width: '100%',
                          backgroundColor: lan === lang.value ? 'green' : 'white',
                          color: lan === lang.value ? 'white' : 'black',
                          border: 'none',
                          padding: '8px',
                          margin: '5px 0',
                        }}
                      >
                        {lang.label}
                      </button>
                    ))}
                  </div>
                </Popup>
              </li>
              <li onClick={handleLogout}>
                <IoMdLogOut className="icon" />
                <Link to="">Logout</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;